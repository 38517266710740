import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import FooterMenu from '../Mainpage/FooterMenu';
import TopMenu from '../Mainpage/TopMenu';
import ApplyJob from './ApplyJob';

function PositionsRequirements() {
  
  const requirements = [
    {
      title:"Intern", 
      description:[

        "Pursuing a degree in IT or a related field.",
        "Deep knowledge in any Programming language.",
        "knowledge in Dart / Flutter will be an added advantage.",
        "Basic understanding of web & mobile app development.",
        "Good team player and have passion & willingness to adopt new technologies.",
        "Good understanding of Git & GitHub."

      ], 
      tasks:[
        "Develop components according to Figma specifications",
        "Assist senior engineers with software development tasks",
        "Learn and apply programming best practices",
        "Make reports & reviews",

      ],},
      {
        title:"Associate Software Engineer - (Flutter)", 
        description:[
        "Prior experience in web and mobile app development",
        "Knowledge of Dart / Flutter is a plus",
        "Proficiency in web and mobile app development concepts",
        "Strong logical thinking skills with the ability to work independently or with minimal guidance",
        "Pursuing a degree in IT or a related field"   
        ], 
        tasks:[
        "Develop user interfaces according to Figma designs",
        "Integrate Google products, such as Firebase",
        "Contribute to web and mobile application development and maintenance",
        "Manage databases effectively",
      ],
    },
    {
      title:"Administrative Assistant", 
      description:[
      "High school diploma or equivalent",
      "Strong organizational and time management skills",
      "Excellent communication and interpersonal skills",
      "Ability to multitask and prioritize tasks effectively"
          
      ], 
      tasks:[
      "Manage office administrative tasks, including scheduling appointments, answering phones, and filing documents",
      "Coordinate meetings and events",
      "Handle customer inquiries and provide support",
      "Maintain office supplies and equipment",
    ],
  },
  {
    title:"Graphics Designer", 
    description:[
    "Basic designing skills",
    "Experience with Illustrator / Photoshop will be added advantage",
    "Excellent communication and interpersonal skills",
    "Creativity & Self motivation"
        
    ], 
    tasks:[
    "Design Marketing materials",
    "Design social media posts",
    "design digital marketing layouts",
  ],
}
  ];

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const positionIndex = params.get("position")
  const position = requirements[positionIndex]
  const applyPosition = position["title"]

   return (
    <div className='flex flex-col'>
    <TopMenu/>
    <div className='h-24'>
     
    </div>
      <div className=' flex justify-center'>
      <div className='w-[150vh] flex flex-col gap-5 items-center justify-center'>
      <div className='w-3/4 flex flex-col items-center justify-center gap-3'>
        <div className='w-full'>
        <h1 className='font-semibold text-3xl'>{position.title}</h1>
        </div>
        <div className='h-4'></div>
        <div className='w-full'>
        <h1 className='font-semibold text-xl'>Duties & Responsibilities</h1>
        </div>
        <div className='w-full flex flex-col gap-2 justify-center'>
        
        {position.tasks.map((task,index)=>(
          <div className='flex gap-3' key={index}>
          <FaCircle className='font-normal text-xs w-1 mt-1'/>
          <h1 className='font-normal'>{task}</h1>
          </div>
        ))}
        </div>
        <div className='h-3'></div>
        <div className='w-full'><h1 className='font-semibold text-xl'>Requirements</h1></div>
        <div className='w-full flex flex-col gap-3 justify-center'>
        {position.description.map((description,index)=>(
          <div className='flex gap-3' key={index}>
          <FaCircle className='font-normal text-xs w-1 mt-1'/>
          <h1 className='font-normal'> {description}</h1>
          </div>
        ))}
        </div>
        <div className='h-7'></div>
       
        </div>
      </div>
      </div>
      <div className='h-24'>
      <ApplyJob applyPosition={applyPosition}/>
      </div>
      <FooterMenu/>
      
    </div>
  )
}

export default PositionsRequirements

/*
 {requirements.map((requirement,index) => (
        <div className='w-3/4 flex flex-col items-center justify-center gap-3' key={index}>
        <div className='w-full'><h1 className='font-bold text-4xl'>{requirement.title}</h1></div>
        
        <div className='w-full'><h1 className='font-semibold text-xl'>Duties & Responsibilities</h1></div>
        <div className='w-full flex flex-col gap-2 justify-center'>
        
        {requirement.tasks.map((task,index)=>(
          <div className='flex gap-3' key={index}>
          <FaCircle className='font-normal text-xs w-1 mt-1'/>
          <h1 className='font-normal'>{task}</h1>
          </div>
        ))}
        </div>
        <div className='w-full'><h1 className='font-semibold text-xl'>Requirements</h1></div>
        <div className='w-full flex flex-col gap-3 justify-center'>{requirement.description.map((description,index)=>(
          <div className='flex gap-2' key={index}>
          <FaCircle className='font-normal text-xs w-1 mt-1'/>
          <h1 className='font-normal'> {description}</h1>
          </div>
        ))}
*/